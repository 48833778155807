#logo {
  top: var(--logo-top);
  padding: var(--logo-padding) 0 var(--logo-padding) 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .3);
  justify-content: center;
  align-self: center;
  width: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

#logo:before {
  content: "";
  width: 100%;
  max-width: 900px;
  position: absolute;
}

#logo #logo-svg {
  width: var(--logo-width);
  pointer-events: none;
  position: relative;
  overflow: hidden;
}

#logo #logo-svg:before, #logo #logo-svg:after {
  content: "";
  background: url("harlekid.311eaf86.svg") 0 0 / cover no-repeat;
  width: 100%;
  position: absolute;
}

#logo #logo-svg:before {
  height: 40%;
}

#logo #logo-svg:after {
  background-position: 0 100%;
  height: 60%;
  top: 40%;
}

#logo #logo-svg img {
  visibility: hidden;
  display: block;
}

#logo #logo-svg.glitch-top:before {
  animation: 50ms step-start infinite glitch-top;
}

#logo #logo-svg.glitch-bottom:after {
  animation: 50ms step-start infinite glitch-bottom;
}

#logo.header {
  padding: 5px 0;
  top: 0;
}

#logo.header #logo-svg {
  width: 180px;
}

#logo.transition {
  transition: top .25s ease-in, padding .25s ease-in;
}

#logo.transition #logo-svg {
  transition: width .5s ease-out .25s;
}

@media only screen and (max-height: 450px) {
  #logo:before {
    width: 240px;
  }
}

@keyframes glitch-top {
  0%, 100% {
    left: 0;
  }

  50% {
    left: -1%;
  }
}

@keyframes glitch-bottom {
  0%, 100% {
    left: 0;
  }

  50% {
    left: 1%;
  }
}

#player {
  z-index: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, .3);
  width: 100%;
  height: 70px;
  display: none;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

#player #cover-wrapper {
  background-color: rgba(0, 0, 0, .3);
  width: 70px;
  height: 70px;
  position: relative;
}

#player #cover-wrapper #cover-button, #player #cover-wrapper #cover {
  width: 70px;
  height: 100%;
  position: absolute;
}

#player #cover-wrapper #play-button {
  background-color: rgba(0, 0, 0, .1);
  padding: 25px;
  position: absolute;
  overflow: hidden;
}

#player #cover-wrapper #play-button #play-icon {
  pointer-events: none;
}

#player #progress-bar {
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}

#player .player-button {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 100%;
  padding-left: 8px;
  display: flex;
}

#player .player-button#spotify-button {
  padding-right: 8px;
}

#player .player-button#spotify-button.hidden {
  visibility: hidden;
}

#player .player-button#spotify-button #spotify-icon {
  width: 22px;
  height: 100%;
  margin-bottom: 1px;
  -webkit-mask: url("spotify.af56eb03.svg") center / contain no-repeat;
}

#player .player-button#close-button {
  padding-right: 16px;
  position: relative;
}

#player .player-button#close-button #cancel-icon {
  opacity: .7;
  width: 18px;
  height: 18px;
}

#player .player-button#close-button:active #cancel-icon {
  opacity: 1;
}

#player #player-text {
  color: #fff;
  flex: 1;
  align-items: center;
  font-family: lemonMilk, serif;
  font-size: 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

#player #player-text #title {
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 18px;
  overflow: hidden;
}

#player.hidden-controls #cover-button {
  display: none;
}

@media only screen and (min-width: 1000px) {
  #player #player-text #title {
    text-align: center;
  }
}

#play-icon {
  width: 20px;
  height: 20px;
  position: relative;
}

#play-icon:before, #play-icon:after {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(255, 255, 255, .9);
  border-top-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 10px;
  width: 0;
  transition: all .1s;
  position: absolute;
}

#play-icon:before {
  border-right-width: 10px;
  height: 10px;
  top: 0;
  left: 0;
}

#play-icon:after {
  border-right-width: 0;
  height: 0;
  top: 5px;
  left: 10px;
}

#play-icon.playing:before, #play-icon.playing:after {
  border-width: 0 0 0 6.66667px;
  height: 20px;
}

#play-icon.playing:after {
  top: 0;
  left: 13.3333px;
}

#footer {
  pointer-events: none;
  height: var(--footer-height);
  z-index: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

#footer .footer-button-group {
  flex-direction: column;
  margin: 0 5px;
  display: flex;
}

#footer .footer-button-group .footer-item {
  height: var(--item-height);
  justify-content: center;
  align-items: center;
  width: 45px;
  display: flex;
  position: relative;
}

#footer .footer-button-group .footer-item#music-button {
  color: rgba(255, 255, 255, .9);
  background-color: rgba(0, 0, 0, .6);
  padding: 0 15px;
  font-family: lemonMilk, serif;
  font-size: 15px;
  animation: 2s infinite pulsate;
  overflow: hidden;
}

#footer .footer-button-group .footer-item .footer-item-content {
  pointer-events: none;
  transform: skew(23deg);
}

#footer .footer-button-group .footer-item .arrow {
  width: 30px;
  margin-top: 15px;
  transition: transform .15s ease-out;
  animation: 2s infinite glow;
}

#footer .footer-button-group.extended .arrow {
  transform: rotate(180deg);
}

#footer.hidden .footer-button-group {
  transform: skew(-23deg) translateY(var(--footer-height)) !important;
}

#footer.hide-transition .footer-button-group {
  transition: transform .5s ease-out .25s;
}

#footer.extend-transition .footer-button-group {
  transition: transform .15s ease-out;
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes pulsate {
  0% {
    margin-top: 2px;
    box-shadow: 0 0 rgba(255, 255, 255, .5);
  }

  70% {
    margin-top: 0;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    margin-top: 2px;
    box-shadow: 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes glow {
  0%, 100% {
    opacity: .5;
    margin-top: 12px;
  }

  70% {
    opacity: 1;
    margin-top: 10px;
  }
}

body {
  background-color: #000;
  justify-content: center;
  margin: 0;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.button {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

#smoke-canvas {
  background-image: linear-gradient(#11111f, #2f223f, #11111f);
  width: 100%;
  overflow: hidden;
}

span.ripple {
  background-color: rgba(255, 255, 255, .5);
  border-radius: 50%;
  animation: .5s linear ripple;
  position: absolute;
  transform: scale(0);
}

@font-face {
  font-family: lemonMilk;
  src: url("LEMONMILK-Bold.76596d34.otf");
}

#stats-container {
  pointer-events: none;
  z-index: 10000;
  align-items: flex-end;
  width: 80px;
  height: 70px;
  transition: transform .1s ease-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#stats-container.hidden {
  transform: translateY(-48px);
}

#stats-container.hidden .arrow {
  transform: rotate(180deg);
}

#stats-container div {
  pointer-events: auto;
}

#stats-container .button {
  display: flex;
}

#stats-container .arrow {
  opacity: .1;
  height: 22px;
  margin: 0 5px;
  transition: transform .1s ease-out;
}

/*# sourceMappingURL=index.cca859d0.css.map */
